import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import axios from 'axios';

const environmentUrls = {
	'local': 'http://localhost:80',
	'staging': 'https://staging.webkarma.com',
	'production': 'https://app.webkarma.com',
}

const queryParameters = new URLSearchParams(window.location.search);
const token = queryParameters.get("token");
const environment = queryParameters.get("env") || 'production';
const accessToken = token;
const apiUrl = environmentUrls[environment];

// The colors listed below need to be added to the tailwind.config.js file in the purge.safelist array
// After adding the colors run npm run build and then npm run start to see the changes

const defaultProps = {
	apiUrl: apiUrl,
	accessToken: accessToken,
	canSendSMS: false,
	locations: [],
	isFormVisibleByDefault: false,
	businessName: '',
	termsLink: '#',
	buttonBgColor: '#000000',
	buttonTextColor: '#ffffff',
	buttonText: 'Text Us',
	headerBgColor: '#000000',
	headerTextColor: '#ffffff',
	headerText: 'Get a quick response via text.',
	submitBgColor: '#000000',
	submitTextColor: '#ffffff',
	submitText: 'Send',
	confirmationMessage: 'Message sent!',
	hasLocationPicker: false,
};

let appConfig = { ...defaultProps };
let isConfigReceived = false; // New variable to track if config is received

async function fetchConfig() {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    try {
        const response = await axios.get(`http://localhost:80/api/v1/chat_config`, { headers, withCredentials: true });
        if (response.status === 200 || response.status === 201) {
            const data = response.data.data;
            updateAppConfig(data);
        }
    } catch (error) {
        console.error('Error fetching config:', error);
    }
}

function updateAppConfig(newConfig) {
    appConfig = { ...appConfig, ...newConfig };
}

const root = createRoot(document.getElementById('root'));

function renderApp() {
    root.render(
        <React.StrictMode>
            <App {...appConfig} />
        </React.StrictMode>
    );
}

function checkAndRenderApp() {
    if (isConfigReceived) {
        renderApp();
    } else {
        setTimeout(checkAndRenderApp, 3000); // Retry after 2 seconds if not ready
    }
}

function setupConfigListener() {
    window.addEventListener('message', (event) => {
        try {
            const data = event.data.data;
            updateAppConfig(data);
            isConfigReceived = true; // Set to true once config is received
            checkAndRenderApp();
        } catch (error) {
            console.error('Error processing message:', error);
        }
    });
    setTimeout(checkAndRenderApp, 3000); // Initial check after 2 seconds
}

if (token) {
    if (environment === 'local') {
        fetchConfig();
    } else {
        setupConfigListener();
    }
}

// Initial call to checkAndRenderApp if needed
checkAndRenderApp(); // Uncomment this if you want to start the check immediately