import React, { useState } from 'react';
import axios from 'axios';

const LeadForm = ({
	apiUrl,
	accessToken,
	canSendSMS,
	submitBgColor,
	submitTextColor,
	submitText,
	businessName,
	termsLink,
	confirmationMessage,
	isFormVisible,
	locationUuid,
	onFormSubmission
}) => {
	
	// if isFormVisible changes from visible to not visible, reset showForm state
	if (!isFormVisible) {
		setShowForm(true);
	}

	const submitButtonStyle = {
		backgroundColor: submitBgColor,
		color: submitTextColor,
	};

	const focusRingColorClass = `focus:ring-gray-300`;
	const successMessage = confirmationMessage || 'Message sent!';

	// Add state to track submission status
	const [showForm, setShowForm] = useState(isFormVisible);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submissionStatus, setSubmissionStatus] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');

	// State to hold form field values
	const [formData, setFormData] = useState({
		location_uuid: locationUuid,
		name: '',
		phone: '',
		email: '',
		message: ''
	});

	// Function to handle form input changes
	const handleChange = (e) => {
		const { name, value } = e.target;
	
		setFormData(prevFormData => ({
			...prevFormData,
			[name]: value
		}));
	};

	// Function to handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);
		setSubmissionStatus(null);
		setErrorMessage('');

		try {
			const response = await axios.post(apiUrl + '/api/v1/leads', formData, {
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': `Bearer ${accessToken}`
				},
				withCredentials: true
			});

			if (response.status === 200 || response.status === 201) {
				setSubmissionStatus('success');
				setShowForm(false);
				setFormData({ location_uuid: '', name: '', phone: '', email: '', message: '' });
				onFormSubmission(true);
			} else {
				throw new Error('Request failed with status code: ' + response.status);
			}
		} catch (error) {
			setSubmissionStatus('error');
			// Axios wraps the response error in error.response
			const message = error.response?.data?.message || error.message || 'Network error. Please try again.';
			setErrorMessage(message);
		}

		setIsSubmitting(false);
	};

	return (
		<div>
			{showForm ? (
				<div>
					<form className="space-y-2 px-6 pb-2 pt-2 mb-4 mt-4" onSubmit={handleSubmit}>
						<div>
							<label htmlFor="name" className="block text-sm font-medium text-gray-900">Name *</label>
							<div className="mt-1">
								<input 
									type="text" 
									name="name" 
									id="name"
									required
									value={formData.name}
									onChange={handleChange}
									className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${focusRingColorClass} sm:text-sm sm:leading-6`} 
								/>
							</div>
						</div>

						{canSendSMS ? (
							<div>
								<label htmlFor="phone" className="block text-sm font-medium text-gray-900">Mobile Phone *</label>
								<div className="mt-1">
									<input 
										type="text" 
										name="phone" 
										id="phone"
										required
										value={formData.phone}
										onChange={handleChange} 
										className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${focusRingColorClass} sm:text-sm sm:leading-6`} 
									/>
								</div>
							</div>
						) : (
							<div>
								<label htmlFor="email" className="block text-sm font-medium text-gray-900">Email *</label>
								<div className="mt-1">
									<input 
										type="text" 
										name="email" 
										id="email"
										required
										value={formData.email}
										onChange={handleChange} 
										className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${focusRingColorClass} sm:text-sm sm:leading-6`} 
									/>
								</div>
							</div>
						)}
						<div>
							<label htmlFor="message" className="block text-sm font-medium text-gray-900">Message *</label>
							<div className="mt-1">
								<textarea 
									rows="4" 
									name="message" 
									id="message"
									required
									value={formData.message}
									onChange={handleChange} 
									className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${focusRingColorClass} sm:text-sm sm:leading-6`}
								></textarea>
							</div>
						</div>
						<button type="submit" style={submitButtonStyle} disabled={isSubmitting} className={`text-center w-full block items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs uppercase tracking-widest focus:outline-none ${focusRingColorClass} focus:ring-2 focus:ring-offset-2 transition ease-in-out duration-150`}>
							{isSubmitting ? 'Submitting...' : (submitText || "Send")}
						</button>
					</form>

					{(canSendSMS) &&
						<p style={{fontSize:'10px'}} className="text-gray-500 px-6 pb-2">
							By submitting, you authorize {businessName} to text and call the number you provided with offers & other information, possibly using automated means. Message/data rates apply. Consent is not a condition of purchase. <a className="underline cursor-pointer" href={`${termsLink}`}>Use is subject to terms</a>.
						</p>
					}

					{/* Render error message if needed */}
					{submissionStatus === 'error' && (
						<div className="text-red-500 text-xs px-6">
							{errorMessage}
						</div>
					)}

				</div>
			) : (
				// Success message goes here
				<div className="p-4">
					<div className="flex rounded-md bg-green-50 p-4">
						<div className="flex-shrink-0">
							<svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
								<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="ml-3">
							<p className="text-sm font-medium text-green-800">{successMessage}</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default LeadForm;