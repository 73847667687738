import React, { useState, useEffect } from 'react';
import ChatBubble from './ChatBubble';
import FormIndex from './FormIndex';

const App = ({ 
    apiUrl, 
    accessToken, 
    canSendSMS, 
    locations, 
    isFormVisibleByDefault, 
    businessName, 
    termsLink, 
    buttonBgColor, 
    buttonTextColor, 
    buttonText, 
    headerBgColor, 
    headerTextColor, 
    headerText, 
    submitBgColor, 
    submitTextColor, 
    submitText, 
    confirmationMessage, 
    hasLocationPicker 
}) => {
    const [isFormVisible, setIsFormVisible] = useState(isFormVisibleByDefault);
    const [locationSelected, setLocationSelected] = useState(null);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const toggleForm = () => {
        setIsFormVisible(prev => !prev);

        // Reset isFormSubmitted to false when form is being reopened
        if (!isFormVisible) {
            setIsFormSubmitted(false);
        }
    };

    const handleLocationSelect = (uuid) => {
        setLocationSelected(uuid);
    };

    const handleFormSubmission = (value) => {
        setIsFormSubmitted(value);
    };

    const handleClose = () => {
        setIsFormVisible(false);
    };

    const isMobileDevice = () => {
        return (window.innerWidth <= 768);
    };

    useEffect(() => {
		var height = 75;
		var width = 160;

		if (isFormVisible && !hasLocationPicker) {
			height = 555;
			width = 350;
		}

		if (isFormVisible && hasLocationPicker && locations?.length > 1 && locationSelected === null) {
			height = 505;
			width = 350;
		}

		if (isFormVisible && hasLocationPicker && locations?.length > 1 && locationSelected !== null) {
			height = 658;
			width = 350;
		}

		if (isFormVisible && hasLocationPicker && locations?.length < 1) {
			height = 555;
			width = 350;
		}

		if (isFormVisible && canSendSMS) {
			height = height + 73;
		}

		if (isFormSubmitted) {
			height = 320;
		}

		window.parent.postMessage({ formVisible: isFormVisible, height: height, width: width, isMobile:isMobileDevice() }, '*');
		return;
    }, [
        isFormVisible, 
        hasLocationPicker, 
        locations?.length, 
        locationSelected, 
        canSendSMS, 
        isFormSubmitted,
    ]);

    return (
        <div className="fixed right-6 bottom-6 group">
        
            {isFormVisible && 
                <FormIndex 
                    apiUrl={apiUrl}
                    accessToken={accessToken}
                    canSendSMS={canSendSMS}
                    locations={locations}
                    businessName={businessName}
                    termsLink={termsLink}
                    headerBgColor={headerBgColor}
                    headerTextColor={headerTextColor}
                    headerText={headerText}
                    submitBgColor={submitBgColor}
                    submitTextColor={submitTextColor}
                    submitText={submitText}
                    confirmationMessage={confirmationMessage}
                    hasLocationPicker={hasLocationPicker}
                    isFormVisible={isFormVisible}
                    onLocationSelect={handleLocationSelect}
                    onFormSubmission={handleFormSubmission}
                    onClose={handleClose}
                />
            }

            <ChatBubble 
                isFormVisible={isFormVisible} 
                onClick={toggleForm}
                buttonBgColor={buttonBgColor}
                buttonTextColor={buttonTextColor}
                buttonText={buttonText}
            />

        </div>
    );
};

export default App;
