import React, { useState } from 'react';

const ChatBubble = ({ 
	isFormVisible, 
	onClick,
	buttonBgColor,
	buttonTextColor,
	buttonText,
}) => {

    const buttonStyle = {
        backgroundColor: buttonBgColor,
        color: buttonTextColor,
    };

	if (isFormVisible) {
		return (
			<button style={buttonStyle} className={`flex items-center justify-center ml-auto font-bold py-2 px-4 rounded`} onClick={onClick}>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
					<path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
				</svg>
			</button>
		);
	} else {
		return (
			<button style={buttonStyle} className={`flex items-center justify-center ml-auto font-bold py-2 px-4 rounded`} onClick={onClick}>
				{buttonText}
			</button>
		);
	}
};

export default ChatBubble;
