import React, { useState, useEffect, useRef } from 'react';
import LeadForm from './LeadForm';
import LocationSelection from './LocationSelection';

const FormIndex = ({
    apiUrl,
    accessToken,
    canSendSMS,
    locations,
    businessName,
    termsLink,
    headerBgColor,
    headerTextColor,
    headerText,
    submitBgColor,
    submitTextColor,
    submitText,
    confirmationMessage,
    hasLocationPicker,
    isFormVisible,
    onLocationSelect,
    onFormSubmission,
    onClose
}) => {

    const [locationSelected, setLocationSelected] = useState(null);
    const [locationName, setLocationName] = useState(null);
    const [locationAddress, setLocationAddress] = useState(null);
    const [keyboardHeight, setKeyboardHeight] = useState(0);

    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    const modalRef = useRef(null);

    useEffect(() => {
        if (isMobile) {
            let originalHeight = window.innerHeight;
            
            const onKeyboardShow = () => {
                const newHeight = window.innerHeight;
                setKeyboardHeight(originalHeight - newHeight);
                originalHeight = newHeight;
            };

            window.addEventListener('resize', onKeyboardShow);

            // Make sure to clean up the event listener when the component unmounts
            return () => {
                window.removeEventListener('resize', onKeyboardShow);
            };
        }
    }, [isMobile]);

    useEffect(() => {
        if (isFormVisible && modalRef.current) {
            modalRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [isFormVisible]);

    if (!isFormVisible) {
        setLocationSelected(null);
        setLocationName(null);
        setLocationAddress(null);
    }

    const headerStyle = {
        backgroundColor: headerBgColor,
        color: headerTextColor,
    };

    const handleLocationSelect = (uuid) => {
        setLocationSelected(uuid);

        if (uuid === null) {
            setLocationName(null);
            onLocationSelect(null);
            onFormSubmission(false);
        } else {
            const location = locations.find(location => location.uuid === uuid);
            setLocationName(location.name);
            setLocationAddress(`${location.street}, ${location.city}, ${location.state}`);
            onLocationSelect(uuid);
        }
    };

    const adjustedHeight = isMobile && keyboardHeight > 0 ? `calc(100vh - ${52 + keyboardHeight + 200}px)` : '100vh';

    return (
        <div className="karma-modal-background-class" ref={modalRef}>
            <div className="karma-modal-class">
                <div className="karma-modal-content-class">

                    <div className="sm:w-80 relative overflow-hidden rounded-lg bg-gray-100 ring-1 ring-gray-900/10 text-left shadow-xl drop-shadow-lg transition ease-in-out duration-150 mb-4">
                        <div className="flex flex-col justify-end">

                            {isMobile && (
                                <div onClick={onClose} className="sm:hidden absolute right-2 top-2 flex w-10 h-10 items-center">
                                    <div type="button" className="relative rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-haze">
                                        <span className="absolute -inset-2.5"></span>
                                        <span className="sr-only">Close panel</span>
                                        <svg className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                        </svg>
                                    </div>
                                </div>
                            )}

                            {(hasLocationPicker && locations.length > 1) ? (
                                <div>
                                    {(locationSelected === null) ? (
                                        <div id="header" style={headerStyle} className={`py-4 px-6 text-sm w-full rounded-t-lg`}>
                                            <h4 className="font-bold pt-2">Select Location</h4>
                                            <p className="font-normal text-xs mt-2 mb-2 leading-5">Select the location you want to contact.</p>
                                        </div>
                                    ) : (
                                        <div id="header" style={headerStyle} className={`py-4 pl-2 pr-4 text-sm w-full sm:rounded-t-lg flex`}>
                                            <div onClick={() => handleLocationSelect(null)} className="cursor-pointer flex-none self-center pr-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                                </svg>
                                            </div>
                                            <div className="flex-1 font-normal mt-2 leading-5">
                                                <h4 className="font-bold pt-2">{locationName}</h4>
                                                <p className="font-normal text-xs mt-2 mb-2 leading-5">{locationAddress}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div id="header" style={headerStyle} className={`flex py-4 px-6 text-sm text-center w-full rounded-t-lg`}>
                                    <p className="mx-auto">{headerText}</p>
                                </div>
                            )}

                            <>
                                {(hasLocationPicker && locations.length > 1 && locationSelected === null) ? (
                                    <div className='h-auto' style={{ height: isMobile ? adjustedHeight : 'auto' }}>
                                        <LocationSelection 
                                            locations={locations}
                                            onLocationSelect={handleLocationSelect}
                                        />

                                        <div id="footer" className={`bg-gray-100 border-t-2 border-gray-200 flex py-3 text-slate-600 px-6 text-xs text-center w-full rounded-b-lg`}>
                                            {(canSendSMS) ? (
                                                <p className="mx-auto">Website texting powered by <a className="underline" target="_blank" href="https://webkarma.com">Karma</a></p>
                                            ): (
                                                <p className="mx-auto">Website chat powered by <a className="underline" target="_blank" href="https://webkarma.com">Karma</a></p>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className='h-auto' style={{ height: isMobile ? adjustedHeight : 'auto' }}>
                                        <LeadForm
                                            apiUrl={apiUrl}
                                            accessToken={accessToken}
                                            canSendSMS={canSendSMS}
                                            submitBgColor={submitBgColor}
                                            submitTextColor={submitTextColor}
                                            submitText={submitText}
                                            businessName={businessName}
                                            termsLink={termsLink}
                                            confirmationMessage={confirmationMessage}
                                            isFormVisible={isFormVisible}
                                            locationUuid={locationSelected}
                                            onFormSubmission={onFormSubmission}
                                        />

                                        <div id="footer" className={`bg-gray-100 border-t-2 border-gray-200 flex py-3 text-slate-600 px-6 text-xs text-center w-full rounded-b-lg`}>
                                            {(canSendSMS) ? (
                                                <p className="mx-auto">Website texting powered by <a className="underline" target="_blank" href="https://webkarma.com">Karma</a></p>
                                            ): (
                                                <p className="mx-auto">Website chat powered by <a className="underline" target="_blank" href="https://webkarma.com">Karma</a></p>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default FormIndex;