import React from 'react';

const LocationSelection = ({locations, onLocationSelect}) => {
    
    return (
        <div className="bg-white sm:max-h-72 overflow-y-auto">
            {locations.map((location) => (
                <div 
                    key={location.uuid}
                    onClick={() => onLocationSelect(location.uuid)} 
                    className="border-b-2 pb-2 last:border-b-0 text-xs hover:bg-gray-100 cursor-pointer px-6 pb-4 pt-4"
                >
                    <h3 className="text-sm font-semibold text-gray-800 truncate">{location.name}</h3>
                    <p className="text-gray-600">{location.street}, {location.city}, {location.state} {location.postal_code}</p>
                </div>
            ))}
        </div>
    );
};

export default LocationSelection;
